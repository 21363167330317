<!-- 贷款记录 -->

<template>
  <div class="loan-record">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <template slot="pulling">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loosing">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loading">
        {{ $i18n.t("rd_loading") }}
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="loadList"
      >
        <template slot="finished">
          {{ $i18n.t("rd_no_more") }}
        </template>
        <template slot="loading">
          {{ $i18n.t("rd_loading") }}
        </template>
        <div class="loan-list">
          <div class="loan-item f28" v-for="(item, index) in loanConfig" :key="index">
            <div class="status ing"></div>
            <div class="loan-name color-000">{{ $i18n.t("lo_scheme") }}#{{ item.ID }}</div>
            <div class="loan-item-info">
              <div class="data-item">
                <div class="word color-666">{{ $i18n.t("lo_loan_amount") }}</div>
                <div class="price">
                  <img src="../assets/images/usdc.svg" alt="" />
                  <div class="num font-b font-bold color-333">{{ item.loanAmount }}</div>
                </div>
              </div>
              <div class="data-item">
                <div class="word color-666">{{ $i18n.t("lo_daily_interest") }}</div>
                <div class="price">
                  <img src="../assets/images/usdc.svg" alt="" />
                  <div class="num font-b font-bold color-333">{{ item.loanRate }}%</div>
                </div>
              </div>
              <div class="data-item">
                <div class="word color-666">{{ $i18n.t("lo_loan_date") }}</div>
                <div class="price">
                  <img src="../assets/images/usdc.svg" alt="" />
                  <div class="num font-b font-bold color-333">{{ item.loanDay }}天</div>
                </div>
              </div>
            </div>
            <div class="apply-info">
              <div class="apply-line f24">
                <div class="label color-999">{{ $i18n.t("loan_payable_interest") }}</div>
                <div class="value color-333">
                  {{ (item.loanAmount * item.loanRate * 0.01 * item.loanDay).toFixed(2) }} USDC
                </div>
              </div>
              <div class="apply-line f24">
                <div class="label color-999">{{ $i18n.t("loan_apply_date") }}</div>
                <div class="value color-333">{{ $moment(handleTime(item.createdAt)).format("YYYY-MM-DD HH:mm:ss") }}</div>
              </div>
              <div class="apply-line f24">
                <div class="label color-999">{{ $i18n.t("loan_lending_time") }}</div>
                <div class="value color-333">
                  <span>{{ $moment(handleTime(item.startAt)).format("YYYY-MM-DD HH:mm:ss") }}</span>
                </div>
              </div>
              <div class="apply-line f24">
                <div class="label color-999">{{ $i18n.t("loan_application_status") }}</div>
                <div class="value color-333">
                  <span class="ing">{{
                    statusObj[item.status] ? $i18n.t(statusObj[item.status]) : ""
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: true,
      loanConfig: [],
      statusObj: {
        waiting: "loan_status_pending",
        refuse: "loan_status_refuse",
        approved: "loan_status_pass",
        ended: "loan_status_ended",
        review: "loan_status_review",
      },
    };
  },
  created() {
    this.getLoanApplyList();
  },
  computed: {
    finishedText() {
      return this.$i18n.t("record_nomore");
    },
  },
  methods: {
    handleTime(date) {
      if (typeof date !== 'string') return date
      return date.replace(/-|\./g,"/").replace(' UTC', '')
    },
    onRefresh() {
      this.getLoanApplyList();
    },
    loadList() {},
    getLoanApplyList() {
      this.$http
        .getLoanApply({
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code == 0) {
            this.loanConfig = res.data.list;
          }
          this.finished = true;
          this.loading = false;
          this.refreshing = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less">
.loan-record {
  padding: 1.36rem 0.48rem;
}
</style>
